import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import {
  AppFeatureSettingsDto,
  CourseProgramDetailsDto,
  NgnExamItemDto,
  NgnRemediationItemDto,
  RemediationDataDto,
  StudyPacketsAndConceptsDto,
  ExamGroupDtoForCreatingNewExamGroup,
  ExamGroupResponseDto,
  ExamGroupStudents,
  ExamSchedulingContentDto,
  RelatedHesiCourseDto,
  CaseStudyPacketMaterialDto,
  StudyPacketDetailDto,
} from './hesi-app-facade-service.dtos';
import { addSearchParams } from '../../utilities/app.utilities';

export const fetchAppFeatureSettings = (courseSectionId: string): Promise<AppFeatureSettingsDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/hesi/app/result/courseSection/${courseSectionId}/getAppFeatureSettings`);
  });
};

export const fetchRelatedHesiCourses = (userId: string): Promise<RelatedHesiCourseDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/hesi/app/user/${userId}/related?appId=hesi`);
  });
};

export const fetchRemediationData = (courseSectionId: string, startDate: number, endDate: number): Promise<RemediationDataDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/hesi/app/analytics/instructor/courseSection/${courseSectionId}/remediationData/testTakingDate?rangeFrom=${startDate}&rangeTo=${endDate}`);
  });
};

export const fetchCurrentCourseProgramDetails = (courseSectionId: string): Promise<CourseProgramDetailsDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/hesi/app/program/details/courseSection/${courseSectionId}`);
  });
};

export const fetchNgnExamItems = (courseSectionId: string, diffNgnReportingDataResponseExamIds: number[]): Promise<NgnExamItemDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/hesi/app/analytics/instructor/courseSection/${courseSectionId}/ngn/items`,
      {
        data: diffNgnReportingDataResponseExamIds
      });
  });
};

export const fetchNgnExamRemediation = (
  courseSectionId: string,
  isAllowedPartialCreditScoring: boolean,
  diffNgnReportingDataResponseExamIds: number[]
): Promise<NgnRemediationItemDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/hesi/app/result/courseSection/${courseSectionId}/ngn/remediation?allowScore=${isAllowedPartialCreditScoring}`,
      {
        data: diffNgnReportingDataResponseExamIds
      });
  });
};

export const fetchStudyPackets = (courseSectionId: string, examGroupIds: number[]): Promise<StudyPacketsAndConceptsDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/hesi/app/result/courseSection/${courseSectionId}/studypackets`,
      {
        data: examGroupIds
      });
  });
};

export const fetchCaseStudyPacketMaterial = (studentEolsId: number, examId: number): Promise<CaseStudyPacketMaterialDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/hesi/app/user/${studentEolsId}/exam/${examId}/caseStudyPacketMaterial`);
  });
};

export const fetchStudyPacketDetail = (studentEolsId: number, examId: number, studyPacketId: number): Promise<StudyPacketDetailDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/hesi/app/user/${studentEolsId}/exam/${examId}/studyPacket/${studyPacketId}`);
  });
};

export const fetchStudyPacketMaterialContent = (contentId: string): Promise<string> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/hesi/app/content/book/${contentId}`);
  });
};

export const fetchExamSchedulingContent = (
  courseSectionId: string,
  userId: string,
  examType: string // RN Fundamentals
): Promise<ExamSchedulingContentDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(`/hesi/app/courseSection/${courseSectionId}/examContent/scheduling`, {
      userId,
      examType
    }));
  });
};
export const fetchExamGroups = (
  courseSectionId: string,
  userId: string,
  examType: string // RN Fundamentals
): Promise<ExamGroupStudents[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(`/hesi/app/instructorExam/courseSection/${courseSectionId}/examGroupStudents`, {
      examType
    }));
  });
};

export const fetchExamGroup = (
  courseSectionId: string,
  examGroupId: string,
): Promise<ExamSchedulingContentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/hesi/app/instructorExam/courseSection/${courseSectionId}/examGroup/${examGroupId}/examGroupStudents`);
  });
};

// Note that hesi team will be redoing and replacing this API
export const fetchExamData = (
  courseSectionId: string,
): Promise<ExamGroupResponseDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/hesi/app/instructorExam/all/courseSection/${courseSectionId}/courseExamData`);
  });
};

// Note that hesi team will be redoing and replacing this API
export const postExamGroup = (
  examGroupDto: ExamGroupDtoForCreatingNewExamGroup,
): Promise<ExamGroupResponseDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/hesi/app/scheduledExam/examGroup', {
      data: examGroupDto
    });
  });
};

export const getExamTypeDetails = (
  props: {
    userId: string;
    productName: string;
    orderType: string;
  }
): Promise<unknown> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/hesi/app/examTypeDetails', {
      ...props
    }));
  });
};
