import { ELSDropDownOption } from '@els/els-component-form-field-react';
import { CaseStudyRequirementType } from '../../constants/hesi.constants';

export const ALL_EXAM_GROUPS = 'ALL_GROUPS';
export const MULTIPLE_EXAM_GROUPS = 'MULTIPLE_GROUPS';
export const ALL_STUDENTS = 'ALL_STUDENTS';

export const CaseStudyMessages: Record<string, string> = {
  [ALL_EXAM_GROUPS]: 'Students who have “Not Available” within their “Requirement met?” column didn’t receive case studies because they were either disabled during exam setup'
    + ' or not available for this exam',
  [CaseStudyRequirementType.TWO_ATTEMPTS]: 'Student must complete all case studies at least once to meet the requirements.'
    + 'Their score on each case study is the average of two possible attempts.',
  [CaseStudyRequirementType.MINIMUM_SCORE]: 'Student must reach a score of 80% on each case study to meet the requirement.',
};

// Date range constants
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const MIN_DATE = 946684800000;

export enum TimeRange {
  ONE_MONTH = '1_MONTH',
  THREE_MONTHS = '3_MONTHS',
  SIX_MONTHS = '6_MONTHS',
  TWELVE_MONTHS = '12_MONTHS',
  CUSTOM_DATE_RANGE = 'CHOOSE_SPECIFIC_DATE_RANGE'
}

export const TimeRangeMonthConstants: Record<TimeRange, number> = {
  [TimeRange.ONE_MONTH]: 1,
  [TimeRange.THREE_MONTHS]: 3,
  [TimeRange.SIX_MONTHS]: 6,
  [TimeRange.TWELVE_MONTHS]: 12,
  [TimeRange.CUSTOM_DATE_RANGE]: 0
};

export const RemediationPerformanceTimeRanges: ELSDropDownOption[] = [
  {
    value: TimeRange.ONE_MONTH,
    name: 'Last month'
  },
  {
    value: TimeRange.THREE_MONTHS,
    name: 'Last 3 months'
  },
  {
    value: TimeRange.SIX_MONTHS,
    name: 'Last 6 months'
  },
  {
    value: TimeRange.TWELVE_MONTHS,
    name: 'Last 12 months'
  },
  {
    value: TimeRange.CUSTOM_DATE_RANGE,
    name: 'Choose specific date range'
  }
];

export const SYSTEM_PROCESSING = 'System Processing';
