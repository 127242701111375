export const APP_LINKING = {
  HESI_NG: {
    NAME: 'HESI_NG',
    PAGES: {
      HESI_INSTRUCTOR_HOME: 'HESI_INSTRUCTOR_HOME',
      HESI_INSTRUCTOR_REMEDIATION: 'HESI_REMEDIATION',
      HESI_INSTRUCTOR_EXAM_SCHEDULING: 'HESI_EXAM_SCHEDULING',

      HESI_STUDENT_HOME: 'HESI_STUDENT_HOME',
      HESI_STUDENT_REMEDIATION: 'HESI_STUDENT_REMEDIATION'
    }
  },
  PERFORMANCE: {
    NAME: 'PERFORMANCE',
    PAGES: {
      HESI_FACULTY_EXAM_RESULTS: 'PERFORMANCE_HESI_NG_EXAM_RESULTS',
      HESI_STUDENT_EXAM_RESULTS: 'PERFORMANCE_HESI_NG_EXAM_RESULTS_STUDENT'
    }
  }
};

export enum UserRole {
  STUDENT = 'Stu',
  INSTRUCTOR = 'Ins',
  DEAN_DIRECTOR = 'DeanDir',
  ADMIN = 'Adm'
}

export enum ProductExamType {
  SPECIALTY = 'Specialty',
  EXIT = 'Exit',
  CAT = 'CAT',
  A2 = 'Admissions',
  CRITICAL_THINKING = 'Critical Thinking'
}

export enum ProductProgramType {
  A2 = 'Admissions'
}

export enum CaseStudyRequirementType {
  TWO_ATTEMPTS = 'TWO_ATTEMPTS',
  MINIMUM_SCORE = 'MINIMUM_SCORE'
}
