import { AppState } from './app-state.types';

export const DefaultAppState: AppState = {
  courseSectionId: null,
  currentCourse: null,
  evolveUser: null,
  featureFlagsGrouped: null,
  isbns: null,
  messages: null,
  pendingRequestCount: 0,
  userId: null,
  eolsUser: null,
  appLinkCookies: null,
  registeredToken: null,
  userRole: null,
  appLinkData: null,
  abTestFlavors: null,
  locale: null,
};
